import { ReactComponent as Bg } from "../../styles/images/tech-bgtech-bg2.svg";
import "./tech.css";
export default function Tech() {
  return (
    <div className="Tech" id="Tech">
      {/* add class for svg-tech-names | delete weird characters | delete rect bg */}
      <Bg />
    </div>
  );
}
